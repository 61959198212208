import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';

import { getStarsLetters } from '../../../services/stars/stars.service';
import { routes } from '../../../services/navigation/navigation.service.routes';

import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import StarDetails from './StarDetails/StarDetails';
import StarsLetterCatalogDisplay from './StarsLetterCatalogDisplay/StarsLetterCatalogDisplay';
import StarsMainView from './StarsMainView/StarsMainView';
import { useWLconfigContext } from '../../../context/WLConfigContext';

import './StarsPage.scss';

const StarsPage = () => {
  const { wl_config: { stars_page_enabled, stars_page_exclusive_enabled } = {} } = useWLconfigContext();
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    if (!stars_page_enabled) return;
    getStarsLetters()
      .then((resp = {}) => {
        const data = resp.data?.data || [];
        setLetters(data);
      })
      .catch((err) => console.error(err));
  }, [stars_page_enabled]);

  if (!stars_page_enabled) return null;

  return (
    <div className="StarsPage">
      <BannerSetViewFunc bannerName="promo" />

      <Router primary={false}>
        <StarsMainView path={'/'} letters={letters} exclusiveStarsEnabled={stars_page_exclusive_enabled} />
        <StarsLetterCatalogDisplay letters={letters} path={routes.starByLetter.replace(routes.stars, '')} />
        <StarDetails path={routes.starInfo.replace(routes.stars, '')} />
      </Router>
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};

StarsPage.displayName = 'StarsPage';

export default StarsPage;
