import 'react-app-polyfill/stable';
import './fonts/yanonekaffeesatz-regular.ttf';
import './fonts/yanonekaffeesatz-regular.woff2';
import './fonts/yanonekaffeesatz-regular.woff';
import './fonts/yanonekaffeesatz-bold.ttf';
import './fonts/yanonekaffeesatz-bold.woff';
import './fonts/yanonekaffeesatz-bold.woff2';
import './fonts/Metropolis-Regular.eot';
import './fonts/Metropolis-Regular.woff2';
import './fonts/Metropolis-Regular.woff';
import './fonts/Metropolis-Regular.ttf';
import './fonts/Metropolis-Black.eot';
import './fonts/Metropolis-Black.woff2';
import './fonts/Metropolis-Black.woff';
import './fonts/Metropolis-Black.ttf';
//import 'https://nakedsword.s3.us-west-1.amazonaws.com/NumberOne-27/ns-forge_ae13a30776ca8876dcbc7f55427a7d90_master.jpeg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4SYHYH7XKO267HIP/20210708/us-west-1/s3/aws4_request&X-Amz-Date=20210708T041743Z&X-Amz-SignedHeaders=host&X-Amz-Expires=86460&X-Amz-Signature=cab826d4102298cfff1784d60aa7bbe948449ce7fc46a8886039c7e30cce2c19';
//import './fonts/Metropolis-Regular.svg#Metropolis-Regular'; // Legacy iOS
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import { createRequestInterceptor, createResponseInterceptor } from './axios-interceptor';

import { env } from './env';

axios.defaults.baseURL = env.REACT_APP_URL;
createRequestInterceptor();
createResponseInterceptor();

function initializeReactApp() {
  ReactDOM.render(<App />, document.getElementById('root'));
}

//React App First
initializeReactApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
