import React, { useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';

import './AgeWarningOverlay.scss';

export const AGE_WARNING_OVERLAY = 'ageWarningOverlay';

const AgeWarningOverlay = () => {
  const { userLoggedIn } = useAuthContext();
  const [showOverlay, setShowOverlay] = useState(() => {
    const overlayDisplayed = parseInt(localStorage.getItem(AGE_WARNING_OVERLAY));
    let value = 0;

    if (overlayDisplayed !== 1 && !userLoggedIn) {
      value = 1;
    } else if (userLoggedIn) {
      value = 0;
    }

    return value;
  });

  const onCloseOverlay = () => {
    setShowOverlay(0);
    localStorage.setItem(AGE_WARNING_OVERLAY, 1);
  };

  if (!showOverlay) return null;

  const getModifiedDomain = () => {
    // Default if the ENV is not set
    const defaultDomain = 'NS';
    const warnDomain = process.env.REACT_APP_PROPERTY_ID || defaultDomain;
    // Removing all periods and appending the rest of the file path
    return warnDomain.replace(/\./g, '') + '_Enter-2bg.jpg';
  };

  // Generate the background image URL using the modified domain
  const backgroundImageUrl = `https://spotlight.nakedcdn.com/warning/${getModifiedDomain()}`;

  return (
    <div className="AgeWarningOverlay" style={{ backgroundImage: `url('${backgroundImageUrl}')` }}>
      <div className="Content">
        <p>
          By entering this site, you confirm you are at least 18 years of age, wish to view adult videos (pornography)
          and it is legal to view adult-related material in your country.
        </p>

        <button className="EnterBtn" onClick={onCloseOverlay}>
          ENTER
        </button>
        <button>
          <a href="https://google.com" className="warningExitLink">
            EXIT
          </a>
        </button>
      </div>
    </div>
  );
};

export default AgeWarningOverlay;
