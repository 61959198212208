import { navigate } from '@reach/router';
import {
  getThemeDetailsRoute,
  getMovieDetailsPageRoute,
  getStudioDetailsRoute,
  getSceneDetailsPageRoute,
  getMoviePlaylistRoute,
  getScenePlaylistRoute,
  getStreamBlockedRoute
} from './navigation.service.routes';
import { parseBannerUrl } from '../banners-service/banners.service';
import { ROUTES } from './navigation.routes';

export const showCompliancePage = () => navigate(`/${ROUTES.compliance}`);

export const showMovieDetailsPage = (movieId, movieName) => navigate(getMovieDetailsPageRoute(movieId, movieName));

export const showSceneDetailsPage = (movieId, movieName, sceneId) =>
  navigate(getSceneDetailsPageRoute(movieId, movieName, sceneId));

export const showApplicationStart = () => navigate(ROUTES.root);

export const showThemesPage = () => navigate(`/${ROUTES.themes}`);

export const showThemeDetailsPage = (id, name) => navigate(getThemeDetailsRoute(id, name));

export const showMyAccountPage = () => navigate(`/${ROUTES.myAccount}`);

export const showHelpPage = () => navigate(`/${ROUTES.help}`);

export const showTermsOfUsePage = () => navigate(`/${ROUTES.termsOfUse}`);

export const showPrivacyPolicyPage = () => navigate(`/${ROUTES.privacyPolicy}`);

export const showMemberDeals = () => navigate(`/${ROUTES.memberDeals}`);

export const showStreamBlockedPage = () => navigate(getStreamBlockedRoute());

export const showEntityDetailsPage = (url, target = '_self') => {
  const {
    movieId,
    movieName,
    moviePlaylistId,
    playlistName,
    sceneIndex,
    scenePlaylistId,
    studioId,
    studioName,
    themeId,
    themeName,
  } = parseBannerUrl(url);

  if (movieId) {
    if (sceneIndex !== undefined) {
      navigate(getSceneDetailsPageRoute(movieId, movieName, sceneIndex), target);
    } else {
      navigate(getMovieDetailsPageRoute(movieId, movieName), target);
    }
  } else if (studioId) {
    navigate(getStudioDetailsRoute(studioId, studioName), target);
  } else if (themeId) {
    navigate(getThemeDetailsRoute(themeId, themeName), target);
  } else if (moviePlaylistId) {
    navigate(getMoviePlaylistRoute(moviePlaylistId, playlistName), target);
  } else if (scenePlaylistId) {
    navigate(getScenePlaylistRoute(scenePlaylistId, playlistName), target);
  } else {
    window.open(url, target);
  }
};

export const showJoinNowPage = () => navigate('/join');

export const showStarLetterListPage = (letter) => navigate(`${ROUTES.stars}/index/${letter}`);
