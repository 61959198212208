import React from 'react';
import { Link } from '@reach/router';

import SinglePlayer from '../SinglePlayer/SinglePlayer';

const data = [
  { url: '/128773/august-alexander', name: 'August Alexander' },
  { url: '/30823/leo-forte', name: 'Leo Forte' },
  { url: '/121217/beaux-banks', name: 'Beaux Banks' },
  { url: '/121827/skyy-knox', name: 'Skyy Knox' },
  { url: '/121747/josh-moore', name: 'Josh Moore' },
  { url: '/116348/dante-colle', name: 'Dante Colle' },
  { url: '/102727/mickey-taylor', name: 'Mickey Taylor' },
  { url: '/31095/cade-maddox', name: 'Cade Maddox' },
  { url: '/99943/adam-ramzi', name: 'Adam Ramzi' },
  { url: '/107547/adrian-hart', name: 'Adrian Hart' },
  { url: '/72459/logan-stevens', name: 'Logan Stevens' },
  { url: '/99971/boomer-banks', name: 'Boomer Banks' },
  { url: '/129879/colton-reece', name: 'Colton Reece' },
  { url: '/64012/max-konnor', name: 'Max Konnor' },
  { url: '/126429/riley-mitchel', name: 'Riley Mitchel' },
  { url: '/98082/sean-zevran', name: 'Sean Zevran' },
  { url: '/127923/wade-wolfgar', name: 'Wade Wolfgar' },
  { url: '/127733/zario-travezz', name: 'Zario Travezz' },
  { url: '/121746/devin-franco', name: 'Devin Franco' },
  { url: '/99245/drew-sebastian', name: 'Drew Sebastian' },
  { url: '/109007/liam-riley', name: 'Liam Riley' },
  { url: '/127642/nic-sahara', name: 'Nic Sahara' },
  { url: '/129147/steven-lee', name: 'Steven Lee' }
];

export default function Pride2020Page() {
  return (
    <>
      <SinglePlayer
        src='https://d34kds0xkdmtq8.cloudfront.net/FSP041_SA.mp4'
      // poster='https://spotlight.nakedcdn.com/nakedsword/img/ns_play/pride2020_16x9.jpg'
      />

      <h2>PRIDE: PORNSTAR COMING OUT STORIES 2020</h2>
      <p>
        Join us here at Weekends at NakedSword as we celebrate PRIDE with these intimate and authentic coming out
        stories from some of the biggest stars in gay porn. These relatable accounts will engage, inspire and tug
        at your heartstrings.
      </p>
      FEATURING:{' '}

      {data.map((item, index) => {
        const { url, name } = item;

        return (
          <Link
            to={`/stars${url}`}
            target="_blank"
            style={{ display: 'inline-block', marginRight: '.3em' }}
            key={url}>
            {name}{index !== data.length - 1 && ', '}
          </Link>
        )
      })}
    </>
  );
}  